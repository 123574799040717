
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "buildId": "184e849b2ad730255cd4d53ed5d0eaffd16abfc4",
  "nuxt": {
    "buildId": "22a44a73-63b1-4eb7-801e-8a273c4ff6c5"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)
